.logo { height: 50px; margin-left: 15px; }

.dashboardtxt {
  font-size: large;
  margin: 0px;
  font-weight: 700;
}

.startexamh2txt {
  font-size: large;
  margin: 40px;
  font-weight: 700;
}

.startexamh2txt2 {
  font-size: large;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700;
}

.answerstxt {
  font-size: medium;
  margin: 40px;
  font-weight: 700;
}

.startexambtn {
  font-size: large;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
}


.startspantxt {
  text-transform: uppercase;
}

.displayblock {
  /* display: block; */
  margin-right: 10px;
  font-size: large;
}

.displayblockbtn {
  /* display: block; */
  margin-right: 7px;
  font-size: medium;
}

.displayblocknext {
  /* display: block; */
  /* margin-right: 20px; */
  font-size: large;
  background: #4f38ff;
  color: #fff;
}

.displayblocknextbtn {
  /* display: block; */
  /* margin-right: 20px; */
  font-size: medium;
  background: #4f38ff;
  color: #fff;
}


.displaynone {
  display: none;
}

.scorebtn {
  font-size: large;
  margin-top: 50px;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
}

.qpagetxt {
  font-size: large;
  margin: 0px;
  font-weight: 700;
  color: rgb(75, 75, 75)
}

.qidtxt {
  font-size: large;
  margin: 0px;
  font-weight: 700;
  color: rgb(0, 18, 117)
}

.questiontxt {
  font-size: medium;
  margin: 0px;
  font-weight: 700;
  color: rgb(0, 0, 0)
}

.notedtxt {
  font-size: medium;
  font-style: italic;
  /* margin: 0px; */
  font-weight: 500;
  color: rgb(0, 15, 146);
  margin-left: 0;
  margin-right: 0;
  margin-top: 20;
  margin-bottom: 20;
}

.correcttxt {
  font-size: medium;
  /* margin: 0px; */
  font-weight: 700;
  color: rgb(30, 129, 0);
  margin-left: 0;
  margin-right: 0;
  margin-top: 20;
  margin-bottom: 20;
}

.wrongtxt {
  font-size: medium;
  /* margin: 0px; */
  font-weight: 700;
  color: rgb(189, 0, 0);
  margin-left: 0;
  margin-right: 0;
  margin-top: 20;
  margin-bottom: 20;
}

.resdiv {
  margin-top: 20px;
}

.ansidentity {
  font-size:medium;
  font-weight: 700;
  color: #000000;
}

.skipicon {
  font-size:smaller;
  font-weight: 700;
  color: #ed9a00;
}

.newicon {
  font-size:smaller;
  font-weight: 700;
  color: blue;
}

span.inline {
  display: inline-flex; /* the default for span */
  padding: 5px;
  margin: 5px;
  border: 1px solid blue;  
  /* background-color: yellow; */
  background-image: linear-gradient(to bottom right, #5591f2, #b655f2);
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(255,255,255,.5);
}


.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
